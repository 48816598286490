import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from './SplitwiseLoginPage.module.css';

const SplitwiseResetPasswordPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (token) {
      navigate('/prathihas-splitwise/groups');
    }
  }, []);

  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [otp, setOtp] = useState(''); // OTP entered by the user
  const [newPassword, setNewPassword] = useState(''); // New password entered by the user
  const [confirmPassword, setConfirmPassword] = useState(''); // Confirm password entered by the user
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [connectionError, setConnectionError] = useState('');
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const [otpVerified, setOtpVerified] = useState(false); // State to track if OTP is verified
  const [isLoading, setIsLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0); // Initially 0
  const [canResend, setCanResend] = useState(true);  // Initially true to show "Send OTP"
  const [successMessage, setSuccessMessage] = useState('');


  const usernameRegex = /^[a-zA-Z0-9_]{5,15}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;


  useEffect(() => {
    if (otpSent && resendTimer > 0) {
      const timer = setTimeout(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (resendTimer === 0) {
      setCanResend(true);
    }
  }, [otpSent, resendTimer]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setError('');
    setConnectionError('');

    if (!canResend) return;

    if (!usernameRegex.test(usernameOrEmail) && !emailRegex.test(usernameOrEmail)) {
      setError('Please enter a valid username or email.');
      setIsSubmitted(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password/otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "usernameOrEmail": usernameOrEmail })
      });

      const data = await response.text();

      if (!response.ok) {
        throw new Error(data);
      }

      // OTP sent successfully
      setOtpSent(true); // Set the otpSent state to true
      setSuccessMessage('Otp sent successfully');
      setCanResend(false);
      setResendTimer(30);
      setError('');
    } catch (error) {
      //console.log(error.message);
      if (error.name === "TypeError" || error.message === "Failed to fetch") {
        setConnectionError("Unable to connect to the server. Please try again later.");
      } else {
        setIsSubmitted(false);
        setError(error.message);
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reset-password/verify`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "email": usernameOrEmail, "otp": otp })
        });

        const jwtToken = await response.text();

        if (!response.ok) {
            throw new Error(jwtToken);
        }

        // OTP verified successfully
        //console.log('OTP verified successfully');
        setSuccessMessage('OTP verified successfully');
        setOtpVerified(true); // Set OTP verified state
        sessionStorage.setItem('jwtToken', jwtToken);
    } catch (error) {
        //console.log(error.message);
        if (error.name === "TypeError" || error.message === "Failed to fetch") {
            setConnectionError("Unable to connect to the server. Please try again later.");
        } else {
            setError(error.message);
        }
    }
  };

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validate new password format
    if (!passwordRegex.test(newPassword)) {
      setError('Password must contain at least one symbol, one number, one uppercase letter, one lowercase letter, and be at least 8 characters long.');
      return;
    }

    const jwtToken = sessionStorage.getItem('jwtToken');

    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/change-password`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`  // Send the token in the Authorization header
        },
        body: JSON.stringify({ "newPassword": newPassword })
      });

      sessionStorage.removeItem('jwtToken');
      const data = await response.text();

      if (!response.ok) {
        throw new Error(data);
      }

      // Password changed successfully
      //console.log('Password changed successfully');
      setSuccessMessage('Password changed successfully. Redirecting to login page...');
      
      setTimeout(() => {
        navigate('/prathihas-splitwise/login');
      }, 2000); // 2 seconds delay
      
    } catch (error) {
      //console.log(error.message);
      setIsLoading(false);
      if (error.name === "TypeError" || error.message === "Failed to fetch") {
        setConnectionError("Unable to connect to the server. Please try again later.");
      } else {
        setError(error.message);
      }
    }
  };

  const handleCancel = () => {
    setUsernameOrEmail(''); 
    setOtp('');             
    setNewPassword('');      
    setConfirmPassword('');  
    setIsSubmitted(false);   
    setOtpSent(false);       
    setOtpVerified(false);   
    setConnectionError('');  
    setError('');            
    setCanResend(false);     
    setResendTimer(30);      
    setIsLoading(false);     
    navigate('/prathihas-splitwise/login'); 
  };

  const handleCancelOtpSent = () => {
    setOtp('');
    setOtpSent(false);
  }

  if (connectionError) {
    return <div className={styles.errorMessage}>{connectionError}</div>;
  }

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {isLoading && (
          <div className={styles.loaderContainer}>
            <div className={styles.loader}></div>
          </div>
        )}
        {connectionError && <div className={styles.errorMessage}>{connectionError}</div>}
        {error && <div className={styles.error}>{error}</div>}
        {successMessage && <div className={styles.signUpMessage}>{successMessage}</div>}
        {!connectionError && (
          <>
            <h1 className={styles.heading}>Reset Your Password</h1>
  
            {!otpSent && !otpVerified && (
              <>
                <p className={styles.para}>Enter your username or email for verification:</p>
                <form onSubmit={handleSubmit}>
                  <div className={styles.formGroup}>
                    <label htmlFor="usernameOrEmail" className={styles.label}>Username/Email:</label>
                    <input
                      type="text"
                      id="usernameOrEmail"
                      value={usernameOrEmail}
                      onChange={(e) => setUsernameOrEmail(e.target.value)}
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.buttonContainer}>
                    <button 
                      type="submit" 
                      className={styles.sendButton} 
                      disabled={!canResend}
                    >
                      {"Send OTP"}
                    </button>
                    <button type="button" className={styles.cancelButton} onClick={handleCancel}>Cancel</button>
                  </div>
                </form>
              </>
            )}

  
            {otpSent && !otpVerified && (
              <>
                <p className={styles.para}>OTP sent successfully. Please enter the OTP below:</p>
                <form onSubmit={handleOtpSubmit}>
                  <div className={styles.formGroup}>
                    <label htmlFor="otp" className={styles.label}>OTP:</label>
                    <input
                      type="text"
                      id="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.buttonContainer}>
                    <button type="submit" className={styles.sendButton}>Verify OTP</button>
                    <button type="button" className={styles.cancelButton} onClick={handleCancelOtpSent}>Cancel</button>
                  </div>
                </form>

                <div className={styles.buttonContainer} style={{ marginTop: '10px' }}>
                  <button
                    type="button"
                    className={styles.sendButton}
                    disabled={!canResend}
                    onClick={handleSubmit}  // Resend OTP handler
                  >
                    {canResend ? "Resend OTP" : `Resend OTP in ${resendTimer}s`}
                  </button>
                </div>
              </>
            )}

  
            {otpVerified && (
              <>
                <p className={styles.para}>Enter your new password below:</p>
                <form onSubmit={handleChangePasswordSubmit}>
                  <div className={styles.formGroup}>
                    <label htmlFor="newPassword" className={styles.label}>New Password:</label>
                    <input
                      type="password"
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label htmlFor="confirmPassword" className={styles.label}>Confirm Password:</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.buttonContainer}>
                    <button type="submit" className={styles.sendButton}>Change Password</button>
                    <button type="button" className={styles.cancelButton} onClick={handleCancel}>Cancel</button>
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SplitwiseResetPasswordPage;