import './App.css';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';

import React from "react";
import SplitwiseLoginPage from './SplitWiseComponents/SplitwiseLoginPage';
import SplitwiseHomePage from './SplitWiseComponents/SplitwiseHomePage';
import SplitwiseLogout from './SplitWiseComponents/SplitwiseLogout';
import SplitwiseSignupPage from './SplitWiseComponents/SplitwiseSignupPage';
import SplitwiseGroupsPage from './SplitWiseComponents/SplitwiseGroupsPage';
import SplitwiseGroupDetail from './SplitWiseComponents/GroupDetail/SplitwiseGroupDetail';
import SplitwiseResetPasswordPage from './SplitWiseComponents/SplitwiseResetPasswordPage';
import NotFoundPage from './SplitWiseComponents/NotFoundPage';

const router = createBrowserRouter([
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: '/',
        element: <SplitwiseHomePage/>,
      },
      {
        path: '/login',
        element: <SplitwiseLoginPage/>
      },
      {
        path: '/signup',
        element: <SplitwiseSignupPage/>
      },
      {
        path: '/logout',
        element: <SplitwiseLogout/>
      },
      {
        path: '/groups',
        element: <SplitwiseGroupsPage/>
      },
      {
        path: '/groups/:groupId', // Route for specific group details
        element: <SplitwiseGroupDetail />
      },
      {
        path: '/reset-password',
        element: <SplitwiseResetPasswordPage/>
      },
      {
        path: '*', // Catch-all route for non-existent pages (404)
        element: <NotFoundPage/>,
      },
])

function App() {

  return (
  <div>
    <RouterProvider router={router} />
  </div>
  );
}

export default App;
